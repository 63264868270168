import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { Grid, Column, Row } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { ButtonLink } from "@ryerson/frontend.navigation";

export type AboutUsHeroContent = {
	title: string;
	years: string;
	subTitle: string;
	description: string[];
	imageUrl: string;
	actionUrl?: string;
	actionLabel: string | string[];
};

export type AboutUsHeroProps = {
	content: AboutUsHeroContent;
};

const TitleDiv = styled.div`
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: max(20px, env(safe-area-inset-right));
	padding-left: max(20px, env(safe-area-inset-left));
	box-sizing: border-box;
	margin-top: -120px;
`;

const SubtitleTextContainer = styled.div`
	display: inline-block;
	white-space: pre-line;
	vertical-align: middle;
	margin-right: 25px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-right: 20px;
			}
		`;
	}}
`;

const DescriptionTextContainer = styled.div`
	display: inline-block;
	width: calc(50% - 40px);
	vertical-align: top;
	margin-right: 40px;
	margin-bottom: 32px;
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 90px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.white};
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-bottom: 34px;
				margin-top: 30px;
			}
		`;
	}}
`;

const ImageDiv = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				height: 200px;
			}
		`;
	}}
`;

const MobileSpacer = styled.div`
	height: 21px;
	width: 100%;
`;

const DesktopSpacer = styled.div`
	height: 135px;
	width: 100%;
`;

const AboutUsHero: React.FC<AboutUsHeroProps> = ({ content }) => {
	const { theme } = useTheme();

	const heroSplit: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkerGray,
				percentage: "100%",
			},
		],
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ImageDiv
					theme={theme}
					css={css`
						background-image: url(${content.imageUrl});
					`}
				></ImageDiv>
				<ContentSection type="split" split={heroSplit}>
					<TitleDiv>
						<Typography
							variant="div"
							color={theme.colors.primary.white}
							css={{ fontSize: "225px", lineHeight: "160px" }}
						>
							{content.title}
						</Typography>
					</TitleDiv>
					<Divider theme={theme}></Divider>
					<Grid>
						<Row>
							<Column lg={6}>
								<SubtitleTextContainer theme={theme}>
									<Typography variant="h1" color={theme.colors.primary.white}>
										{content.years}
									</Typography>
								</SubtitleTextContainer>
								<SubtitleTextContainer theme={theme}>
									<Typography
										variant="p"
										color={theme.colors.primary.white}
										size="lg"
									>
										{content.subTitle}
									</Typography>
								</SubtitleTextContainer>
							</Column>
							<Column lg={6}>
								{content.description.map((string: string, index: number) => {
									return (
										<DescriptionTextContainer key={index}>
											<Typography
												variant="p"
												type="tertiary"
												size="md"
												key={index}
											>
												{string}
											</Typography>
										</DescriptionTextContainer>
									);
								})}
								{content.actionUrl ? (
									<ButtonLink
										label={content.actionLabel}
										to={content.actionUrl}
										linkType="external"
										type="tertiary"
									/>
								) : (
									<></>
								)}
							</Column>
						</Row>
					</Grid>
					<DesktopSpacer></DesktopSpacer>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ImageDiv
					theme={theme}
					css={css`
						background-image: url(${content.imageUrl});
					`}
				></ImageDiv>
				<ContentSection type="split" split={heroSplit}>
					<TitleDiv
						css={css`
							margin-top: -45px;
							padding-left: 0px;
							padding-right: 0px;
						`}
					>
						<Typography variant="h1" type="tertiary">
							{content.title}
						</Typography>
					</TitleDiv>
					<Divider theme={theme}></Divider>
					<SubtitleTextContainer
						theme={theme}
						css={css`
							width: 90px;
							margin-right: 10px !important;
						`}
					>
						<Typography variant="h1" color={theme.colors.primary.white}>
							{content.years}
						</Typography>
					</SubtitleTextContainer>
					<SubtitleTextContainer
						theme={theme}
						css={css`
							width: calc(100% - 110px);
							margin-right: 0px !important;
						`}
					>
						<Typography variant="p" color={theme.colors.primary.white} size="md">
							{content.subTitle}
						</Typography>
					</SubtitleTextContainer>
					{content.description.map((string: string, index: number) => {
						return (
							<Typography variant="p" type="tertiary" size="md" key={index}>
								{string}
							</Typography>
						);
					})}
					{content.actionUrl ? (
						<>
							<MobileSpacer></MobileSpacer>
							<ButtonLink
								label={content.actionLabel}
								to={content.actionUrl}
								type="tertiary"
							/>
							<MobileSpacer></MobileSpacer>
						</>
					) : (
						<></>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default AboutUsHero;
